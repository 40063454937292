<template>
    <div class="modal-edit-style-type items-start bg-main">
        <!-- <div class="header" @click="submit">
            <i class="material-icons m-16">chevron_left</i>
        </div> -->
        <div class="header">
            <i class="material-icons m-16" @click="submit">chevron_left</i>
            <div v-if="$isAndroid()" @click="submit" class="edit-profile-complete m-r-4">저장</div>
        </div>
        <div class="body" ref="body">
            <div class="title m-l-20 f-medium">
                {{ $translate(`MODAL_${options.key.toUpperCase()}_TYPE`) }}
            </div>
            <ul class="description-area m-20">
                <li
                    v-if="getDescription"
                    class="description c-grey-07 f-14 m-20"
                    style="list-style-type: disc; color: #666b70"
                    v-html="getDescription"
                />
                <li
                    v-if="getDescriptionSecond"
                    class="description c-grey-07 f-14 m-20"
                    style="list-style-type: disc; color: $c-danger"
                    v-html="getDescriptionSecond"
                />
            </ul>
            <div class="body">
                <TextareaWithX
                    v-model="inputValue"
                    :placeholder="placeholder"
                    :textareaRows="5"
                    :ref="'comment'"
                    :setClass="'content-area m-0'"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalEditAppearanceType',
    props: ['options'],
    data() {
        return {
            inputValue: '',
        }
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        getDescription() {
            return '너무 구체적인 요구사항은 만족시켜드리기 어려울 수 있습니다. 대략적으로 선호하는 외모나 인상에 대해 설명해주시면 좋습니다.'
        },
        getDescriptionSecond() {
            return '매니저는 적어주신 내용과 받은 프로필에 대한 외모 평가 데이터를 함께 보고 매칭해드립니다. '
        },
        placeholder() {
            if (!this.inputValue || this.options.text === 'null' || this.options.text === null) {
                return '선호하는/선호하지 않는 외모 스타일을 적어주세요'
            } else {
                return this.options.text
            }
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.inputValue = this.options.text === 'null' || this.options.text === null ? '' : this.options.text
        },
        submit() {
            this.$emit('close', { items: this.inputValue })
        },
    },
}
</script>
<style scoped lang="scss">
.modal-edit-style-type {
    overflow-y: hidden;
}
.body {
    padding: 30px 0;
    overflow-y: auto;
}
.header {
    position: sticky;
    max-height: 52px;
    top: 0;
    background: #faf0e1;
    display: flex;
    // justify-content: flex-start;
    justify-content: space-between;
    align-items: center;
    // padding-left: 16px;
    // padding: 16px;
}
.edit-profile-complete {
    padding: 12px 16px;
    color: #151360;
    font-weight: 600;
    font-size: 15px;
}
.title {
    font-size: 18px;
    display: flex;
    // text-align: center;
    align-items: flex-end;
    color: #151360;
}
.body {
    padding: 10px;
}
.description {
    line-height: 24px;
}
</style>
